import { USER_ROLES } from 'constants/userRoles'

const rolePriorityOrder: USER_ROLES[] = [
  USER_ROLES.SUPER_USER,
  USER_ROLES.SYS_ADMIN,
  USER_ROLES.SITE_USER_ADMIN,
  USER_ROLES.SITE_USER,
  USER_ROLES.PROVIDER,
  USER_ROLES.PRACTICE_USER,
  USER_ROLES.INDIVIDUAL,
]

export const getRolePriority = (roles: USER_ROLES[]) => {
  return roles
    ? rolePriorityOrder.find((role) => roles.includes(role))
    : USER_ROLES.INDIVIDUAL
}
