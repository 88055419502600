import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'
import { REHYDRATE } from 'redux-persist'
import {
  REPORT_QA_REASONS,
  TBodyPart,
  TModality,
  TProcedure,
} from 'services/dictionary/types'
import { customPeriodOption } from 'components/filters/DateRangeFilter/constants'
import { ORDER_STATUS_ENUM } from 'store/order/types'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { TAG_TYPE } from 'store/tags/types'
import { removeProceduresFiltersOption } from 'utils/helpers/filters/removeProceduresFiltersOptions'

import { initialState, ORDERS_FILTERS_SLICE_NAME } from './constants'
import {
  ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS,
  IAllOrdersFiltersData,
  IAssignmentOrdersFiltersData,
  IBillingOrdersFiltersData,
  IOrderTableQueryData,
  IPreparationOrdersFiltersData,
  IReportQAOrdersFiltersData,
} from './types'

const ordersFiltersSlice = createSlice({
  name: ORDERS_FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    changeTableQueryDataByName: (
      state,
      {
        payload,
      }: PayloadAction<{
        tableName: ORDERS_TABLE_TAB
        query: Partial<IOrderTableQueryData>
      }>
    ) => {
      const tableData = state[payload.tableName]

      if (tableData) {
        tableData.queryData = {
          ...tableData.queryData,
          ...payload.query,
        }
      }
    },
    configureAllRisOrdersTableColumns: (
      state,
      {
        payload: { columnName },
      }: PayloadAction<{ columnName: ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS }>
    ) => {
      const tableData = state[ORDERS_TABLE_TAB.ALL]

      tableData.columnsConfiguration = {
        ...tableData.columnsConfiguration,
        [columnName]: !tableData.columnsConfiguration[columnName],
      }
    },
    changeFilterDataByTableName: (
      state,
      {
        payload,
      }: PayloadAction<
        | {
            tableName: ORDERS_TABLE_TAB.ALL
            data: Partial<IAllOrdersFiltersData>
          }
        | {
            tableName: ORDERS_TABLE_TAB.PREPARATION
            data: Partial<IPreparationOrdersFiltersData>
          }
        | {
            tableName: ORDERS_TABLE_TAB.ASSIGNMENT
            data: Partial<IAssignmentOrdersFiltersData>
          }
        | {
            tableName: ORDERS_TABLE_TAB.REPORT_QA
            data: Partial<IReportQAOrdersFiltersData>
          }
        | {
            tableName: ORDERS_TABLE_TAB.BILLING
            data: Partial<IBillingOrdersFiltersData>
          }
      >
    ) => {
      const tableData = state[payload.tableName]

      if (tableData) {
        tableData.filtersData = {
          ...tableData.filtersData,
          ...payload.data,
        }
        tableData.queryData.page = 0
      }
    },
    clearAllOrdersTableFiltersData: (state) => {
      const tableData = state[ORDERS_TABLE_TAB.ALL]

      tableData.filtersData = {
        ...initialState[ORDERS_TABLE_TAB.ALL].filtersData,
        dateOfService: {
          period: customPeriodOption,
          from: null,
          to: null,
        },
        isInactiveStatuses: false,
      }
      tableData.queryData.page = 0
    },
    clearBillingOrdersTableFiltersData: (state) => {
      const tableData = state[ORDERS_TABLE_TAB.BILLING]

      tableData.filtersData = {
        ...initialState[ORDERS_TABLE_TAB.BILLING].filtersData,
        billingStatuses: [],
      }
      tableData.queryData.page = 0
    },
    resetAllFiltersDataByTableName: (
      state,
      { payload }: PayloadAction<{ tableName: ORDERS_TABLE_TAB }>
    ) => {
      const tableName = payload.tableName
      const tableData = state[tableName]

      tableData.filtersData = initialState[tableName].filtersData
      tableData.queryData.page = 0
    },
    clearAllFiltersDataByTableName: (
      state,
      { payload }: PayloadAction<{ tableName: `${ORDERS_TABLE_TAB}` }>
    ) => {
      const tableName = payload.tableName
      const tableData = state[tableName]

      tableData.filtersData = {
        ...initialState[tableName].filtersData,
        ...(tableName === ORDERS_TABLE_TAB.ALL
          ? {
              dateOfService: {
                from: null,
                to: null,
                period: null,
              },
              isInactiveStatuses: false,
            }
          : {}),
      }
      tableData.queryData.page = 0
    },

    resetFilterDataByFilterName: (
      state,
      {
        payload,
      }: PayloadAction<{
        tableName: ORDERS_TABLE_TAB
        filterName:
          | keyof IAllOrdersFiltersData
          | keyof IPreparationOrdersFiltersData
          | keyof IAssignmentOrdersFiltersData
          | keyof IReportQAOrdersFiltersData
          | keyof IBillingOrdersFiltersData
      }>
    ) => {
      const tableName = payload.tableName
      const tableData = state[tableName]

      if (tableName === ORDERS_TABLE_TAB.PREPARATION) {
        const filterName =
          payload.filterName as keyof IPreparationOrdersFiltersData

        tableData.filtersData = {
          ...tableData.filtersData,
          [filterName]: initialState[tableName].filtersData[filterName],
        }
        tableData.queryData.page = 0
      }

      if (tableName === ORDERS_TABLE_TAB.ASSIGNMENT) {
        const filterName =
          payload.filterName as keyof IAssignmentOrdersFiltersData

        tableData.filtersData = {
          ...tableData.filtersData,
          [filterName]: initialState[tableName].filtersData[filterName],
        }
        tableData.queryData.page = 0
      }

      if (tableName === ORDERS_TABLE_TAB.REPORT_QA) {
        const filterName =
          payload.filterName as keyof IReportQAOrdersFiltersData

        tableData.filtersData = {
          ...tableData.filtersData,
          [filterName]: initialState[tableName].filtersData[filterName],
        }
        tableData.queryData.page = 0
      }

      if (tableName === ORDERS_TABLE_TAB.ALL) {
        const filterName = payload.filterName as keyof IAllOrdersFiltersData

        tableData.filtersData = {
          ...tableData.filtersData,
          [filterName]: initialState[tableName].filtersData[filterName],
        }
        tableData.queryData.page = 0
      }

      if (tableName === ORDERS_TABLE_TAB.BILLING) {
        const filterName = payload.filterName as keyof IBillingOrdersFiltersData

        tableData.filtersData = {
          ...tableData.filtersData,
          [filterName]: initialState[tableName].filtersData[filterName],
        }
        tableData.queryData.page = 0
      }
    },
    clearProcedureGroupFiltersDataByTableName: (
      state,
      { payload }: PayloadAction<{ tableName: ORDERS_TABLE_TAB }>
    ) => {
      const tableData = state[payload.tableName]

      if (tableData) {
        tableData.filtersData = {
          ...tableData.filtersData,
          bodyParts: [],
          modalities: [],
          procedures: [],
          cptCodes: [],
        }
        tableData.queryData.page = 0
      }
    },
    clearRISOrderStatusFilter: (state) => {
      const tableData = state[ORDERS_TABLE_TAB.ALL]

      tableData.filtersData = {
        ...tableData.filtersData,
        isInactiveStatuses: false,
        orderStatuses: [],
      }
    },
    // reducers to remove specific filters options form Applied filters panel
    removePreparationOrdersHoldReasonStatusFilterOptionById: (
      state,
      { payload: { id } }: PayloadAction<{ id: number }>
    ) => {
      const preparationTableData = state[ORDERS_TABLE_TAB.PREPARATION]

      preparationTableData.filtersData = {
        ...preparationTableData.filtersData,
        holdReasons: preparationTableData.filtersData.holdReasons.filter(
          (holdReason) => holdReason.id !== id
        ),
      }
      preparationTableData.queryData.page = 0
    },
    removeAssignmentOrdersSiteStateFilterOptionByStateKey: (
      state,
      { payload: { stateKey } }: PayloadAction<{ stateKey: string }>
    ) => {
      const assignmentTableData = state[ORDERS_TABLE_TAB.ASSIGNMENT]

      assignmentTableData.filtersData.states =
        assignmentTableData.filtersData.states.filter(
          (state) => state !== stateKey
        )
      assignmentTableData.queryData.page = 0
    },
    removeUrgencyFilterOptionById: (
      state,
      {
        payload: { tableName, id },
      }: PayloadAction<{
        tableName: Exclude<ORDERS_TABLE_TAB, ORDERS_TABLE_TAB.BILLING>
        id: number
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData = {
        ...tableData.filtersData,
        urgencies: tableData.filtersData.urgencies.filter(
          (urgency) => urgency.id !== id
        ),
      }
      tableData.queryData.page = 0
    },
    removeProcedureGroupFilterOptionById: (
      state,
      {
        payload: { tableName, procedureGroupFilterName, id },
      }: PayloadAction<{
        tableName: ORDERS_TABLE_TAB
        procedureGroupFilterName: 'bodyParts' | 'modalities' | 'procedures'
        id: number
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData = {
        ...tableData.filtersData,
        [procedureGroupFilterName]: removeProceduresFiltersOption<
          TBodyPart | TModality | TProcedure
        >(tableData.filtersData[procedureGroupFilterName], id),
      }
      tableData.queryData.page = 0
    },
    removeProcedureCPTCodeFilterOptionById: (
      state,
      {
        payload: { id, tableName },
      }: PayloadAction<{
        id: string
        tableName: ORDERS_TABLE_TAB.BILLING | ORDERS_TABLE_TAB.ALL
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData = {
        ...tableData.filtersData,
        cptCodes: tableData.filtersData.cptCodes.filter(
          ({ code }) => code !== id
        ),
      }
      tableData.queryData.page = 0
    },
    removeBillingTypesFilterOptionById: (
      state,
      {
        payload: { id, tableName },
      }: PayloadAction<{
        id: number
        tableName: ORDERS_TABLE_TAB.BILLING | ORDERS_TABLE_TAB.ALL
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData = {
        ...tableData.filtersData,
        billingTypes: tableData.filtersData.billingTypes.filter(
          (billingType) => billingType.id !== id
        ),
      }
      tableData.queryData.page = 0
    },
    removeBillingStatusesFilterOptionByName: (
      state,
      {
        payload: { name, tableName },
      }: PayloadAction<{
        name: string
        tableName: ORDERS_TABLE_TAB.BILLING | ORDERS_TABLE_TAB.ALL
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData = {
        ...tableData.filtersData,
        billingStatuses: tableData.filtersData.billingStatuses.filter(
          (option) => option.name !== name
        ),
      }
      tableData.queryData.page = 0
    },
    removeSiteNameFilterOptionById: (
      state,
      {
        payload: { tableName, id },
      }: PayloadAction<{
        tableName: ORDERS_TABLE_TAB
        id: number
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData.siteNames = tableData.filtersData.siteNames.filter(
        ({ siteId }) => siteId !== id
      )
      tableData.queryData.page = 0
    },
    removeSystemTagFilterOptionById: (
      state,
      {
        payload: { tableName, id },
      }: PayloadAction<{
        tableName: ORDERS_TABLE_TAB
        id: number
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData.systemTags =
        tableData.filtersData.systemTags.filter(
          ({ id: systemTagId }) => systemTagId !== id
        )
      tableData.queryData.page = 0
    },
    removeTagFilterOptionById: (
      state,
      {
        payload: { tableName, id, tagType },
      }: PayloadAction<{
        tableName: ORDERS_TABLE_TAB
        id: number
        tagType: TAG_TYPE
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData.tags[tagType] = tableData.filtersData.tags[
        tagType
      ].filter((tag) => tag.id !== id)
      tableData.queryData.page = 0
    },
    removeCompanyFilterOptionById: (
      state,
      {
        payload: { id },
      }: PayloadAction<{
        id: number
      }>
    ) => {
      const tableData = state[ORDERS_TABLE_TAB.BILLING]

      tableData.filtersData.companies = tableData.filtersData.companies.filter(
        ({ companyId }) => companyId !== id
      )
      tableData.queryData.page = 0
    },
    removeInsuranceGroupName: (
      state,
      {
        payload: { group },
      }: PayloadAction<{
        group: string
      }>
    ) => {
      const tableData = state[ORDERS_TABLE_TAB.BILLING]

      tableData.filtersData.insuranceGroupNames =
        tableData.filtersData.insuranceGroupNames.filter(
          (groupName) => groupName !== group
        )
      tableData.queryData.page = 0
    },
    removeAssignedRadiologistFilterOption: (
      state,
      {
        payload: { id, tableName },
      }: PayloadAction<{
        tableName: Exclude<ORDERS_TABLE_TAB, ORDERS_TABLE_TAB.PREPARATION>
        id: string
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData.radiologists =
        tableData.filtersData.radiologists.filter(
          ({ providerId }) => providerId !== id
        )
      tableData.queryData.page = 0
    },
    removeAppliedContributingReaderFilterOption: (
      state,
      {
        payload: { id, tableName },
      }: PayloadAction<{
        tableName: ORDERS_TABLE_TAB.REPORT_QA | ORDERS_TABLE_TAB.ALL
        id: string
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData.contributingReaders =
        tableData.filtersData.contributingReaders.filter(
          ({ providerId }) => providerId !== id
        )
      tableData.queryData.page = 0
    },
    removeAppliedReportQAReasonFilterOptionById: (
      state,
      {
        payload: { option },
      }: PayloadAction<{
        option: `${REPORT_QA_REASONS}`
      }>
    ) => {
      const tableData = state[ORDERS_TABLE_TAB.REPORT_QA]

      tableData.filtersData.reportQAReasons =
        tableData.filtersData.reportQAReasons.filter(
          (optionItem) => optionItem.value !== option
        )
      tableData.queryData.page = 0
    },
    removeAssignedPhysicianFilterOption: (
      state,
      {
        payload: { id, tableName },
      }: PayloadAction<{
        id: string
        tableName:
          | ORDERS_TABLE_TAB.ASSIGNMENT
          | ORDERS_TABLE_TAB.BILLING
          | ORDERS_TABLE_TAB.ALL
      }>
    ) => {
      const tableData = state[tableName]

      tableData.filtersData.physicians =
        tableData.filtersData.physicians.filter(
          ({ providerId }) => providerId !== id
        )
      tableData.queryData.page = 0
    },
    removeStatusFilterOptionByStatus: (
      state,
      {
        payload: { status },
      }: PayloadAction<{ status: `${ORDER_STATUS_ENUM}` | null }>
    ) => {
      const tableData = state[ORDERS_TABLE_TAB.ALL]

      tableData.filtersData = {
        ...tableData.filtersData,
        orderStatuses: tableData.filtersData.orderStatuses.filter(
          (option) => option.value !== status
        ),
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      // @ts-expect-error action has a payload with rehydrated state
      if (action.payload) {
        const {
          // @ts-expect-error action has a payload with rehydrated state
          payload: { filters },
        } = action
        return _merge(state, filters?.ordersFilters ?? {})
      }

      return state
    })
  },
})

export const {
  changeTableQueryDataByName,
  configureAllRisOrdersTableColumns,
  changeFilterDataByTableName,
  resetFilterDataByFilterName,
  clearAllOrdersTableFiltersData,
  clearBillingOrdersTableFiltersData,
  clearProcedureGroupFiltersDataByTableName,
  resetAllFiltersDataByTableName,
  clearAllFiltersDataByTableName,
  removePreparationOrdersHoldReasonStatusFilterOptionById,
  removeAssignmentOrdersSiteStateFilterOptionByStateKey,
  removeUrgencyFilterOptionById,
  removeProcedureGroupFilterOptionById,
  removeProcedureCPTCodeFilterOptionById,
  removeBillingTypesFilterOptionById,
  removeBillingStatusesFilterOptionByName,
  removeSystemTagFilterOptionById,
  removeSiteNameFilterOptionById,
  removeTagFilterOptionById,
  removeCompanyFilterOptionById,
  removeAssignedRadiologistFilterOption,
  removeAppliedContributingReaderFilterOption,
  removeAppliedReportQAReasonFilterOptionById,
  removeAssignedPhysicianFilterOption,
  removeInsuranceGroupName,
  removeStatusFilterOptionByStatus,
  clearRISOrderStatusFilter,
} = ordersFiltersSlice.actions

export default ordersFiltersSlice
