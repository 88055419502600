import { createAsyncThunk } from '@reduxjs/toolkit'
import request from 'utils/api/request'
import { TServiceError } from 'utils/api/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'
import { TPageable } from 'types/general'

import {
  TAG_STATUS,
  TTagCreationRq,
  TTagCreationRs,
  TTagRs,
  TTagsFilter,
  TTagsPageRs,
  TTagUpdateRq,
} from './types'

export const createTag = createAsyncThunk<
  TTagCreationRs,
  TTagCreationRq,
  {
    rejectValue: TServiceError
  }
>('tags/createTag', async (data, { rejectWithValue }) => {
  const result = await request<TTagCreationRs>({
    method: 'POST',
    url: '/orders/tags',
    data,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const updateTag = createAsyncThunk<
  unknown,
  TTagUpdateRq & { id: number },
  {
    rejectValue: TServiceError
  }
>('tags/createTag', async ({ id, ...data }, { rejectWithValue }) => {
  const result = await request<unknown>({
    method: 'PUT',
    url: `/orders/tags/${id}`,
    data,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchTags = createAsyncThunk<
  TTagsPageRs,
  { pageable: TPageable; filter?: TTagsFilter },
  {
    rejectValue: TServiceError
  }
>('tags/fetchTags', async ({ pageable, filter }, { rejectWithValue }) => {
  const params = {
    ...pageable,
    search: filter?.search,
    statuses: prepareArrayForSending(filter?.statuses ?? []),
    isPaged: true,
  }
  const result = await request<TTagsPageRs>({
    method: 'GET',
    url: '/orders/tags',
    params,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})

export const fetchAllTags = createAsyncThunk<
  TTagRs[],
  TAG_STATUS[],
  {
    rejectValue: TServiceError
  }
>('tags/fetchAllTags', async (status, { rejectWithValue }) => {
  const params = {
    statuses: prepareArrayForSending(status),
    isPaged: false,
  }
  const result = await request<TTagsPageRs>({
    url: '/orders/tags',
    method: 'GET',
    params,
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data.content
})

export const fetchTagArchive = createAsyncThunk<
  unknown,
  { status: TAG_STATUS; id: string | number },
  {
    rejectValue: TServiceError
  }
>('tags/archiveTag', async (payload, { rejectWithValue }) => {
  const result = await request<unknown>({
    url: `/orders/tags/${payload.id}`,
    data: { status: payload.status },
    method: 'PATCH',
  })

  if (result.error) {
    return rejectWithValue(result.error)
  }

  return result.data
})
