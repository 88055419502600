import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import _omit from 'lodash/omit'
import { isRequiredFlagUncompleted } from 'store/assignProvidersTable/helpers/isRequiredFlagUncompleted'
import { LOADING_STATE } from 'types/general'

import { initialState, sliceName } from './constants'
import {
  assignOrdersRequest,
  fetchOrdersFlags,
  fetchOrdersReassignFlags,
  fetchPreviouslyAssignedRadiologists,
  getAssignRadiologists,
  linkAndLockOrdersRequest,
  unlockOrdersRequest,
} from './controllers'
import { isSingleOrderAssignment } from './helpers/isSingleOrderAssignment'
import { mapGroupsAssignment } from './helpers/mapGroupsAssignment'
import { mapSingleOrderAssignment } from './helpers/mapSingleOrderAssignment'
import {
  ASSIGNMENT_RADIOLOGIST_TAB,
  IState,
  ITabQueryData,
  TLinkedOrderPatientInfo,
} from './types'

const assignRadiologistsTableSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    completeAllFlagsLocally: (
      state,
      {
        payload: { completedBy },
      }: PayloadAction<{
        completedBy: {
          firstName?: string
          lastName?: string
        }
      }>
    ) => {
      state.flags.list = state.flags.list.map((flag) => {
        if (isRequiredFlagUncompleted(flag)) {
          return {
            ...flag,
            completedInfo: { completedAt: dayjs().toString(), completedBy },
          }
        }
        return flag
      })
    },
    completeFlagLocally: (
      state,
      {
        payload: { flagId, isCompleted, completedBy },
      }: PayloadAction<{
        flagId: number
        isCompleted: boolean
        completedBy: {
          firstName?: string
          lastName?: string
        }
      }>
    ) => {
      state.flags.list = state.flags.list.map((flag) => {
        if (flag.flagId === flagId) {
          return {
            ...flag,
            completedInfo: isCompleted
              ? { completedAt: dayjs().toString(), completedBy }
              : undefined,
          }
        }
        return flag
      })
    },
    resetProviderAssignmentTableInfo: (state) => {
      state.groups.queryData = initialState.groups.queryData
      state.radiologists.queryData = initialState.radiologists.queryData
      state.assignQuery.assignmentTab = initialState.assignQuery.assignmentTab
    },
    resetOrdersFlags: (state) => {
      state.flags = initialState.flags
    },
    resetPreviouslyAssignedRadiologists: (state) => {
      state.previouslyAssignedRadiologists =
        initialState.previouslyAssignedRadiologists
    },
    setPreviouslyAssignedRadiologistsPageable: (state, { payload }) => {
      state.previouslyAssignedRadiologists.pageable = {
        ...state.previouslyAssignedRadiologists.pageable,
        ...payload,
      }
    },
    changeAssignTableQuery: (
      state,
      { payload }: PayloadAction<Partial<ITabQueryData>>
    ) => {
      const currentTab: ASSIGNMENT_RADIOLOGIST_TAB =
        state.assignQuery.assignmentTab

      state[currentTab].queryData = {
        ...state[currentTab].queryData,
        ...payload,
      }
    },
    changeAssignmentQuery: (
      state,
      { payload }: PayloadAction<Partial<IState['assignQuery']>>
    ) => {
      state.assignQuery = {
        ...state.assignQuery,
        ...payload,
      }
    },
    updateAssignQuery: (state, { payload }: PayloadAction<string>) => {
      const accessionNumberIndex =
        state.assignQuery.accessionNumbers.indexOf(payload)

      if (accessionNumberIndex >= 0) {
        state.assignQuery.accessionNumbers =
          state.assignQuery.accessionNumbers.filter(
            (accessionNumber) => payload !== accessionNumber
          )
      } else {
        state.assignQuery.accessionNumbers = [
          ...state.assignQuery.accessionNumbers,
          payload,
        ]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssignRadiologists.pending, (state) => {
        state.groups.loadingState =
          state.assignQuery.assignmentTab === ASSIGNMENT_RADIOLOGIST_TAB.GROUPS
            ? LOADING_STATE.pending
            : LOADING_STATE.idle
        state.radiologists.loadingState =
          state.assignQuery.assignmentTab ===
          ASSIGNMENT_RADIOLOGIST_TAB.RADIOLOGISTS
            ? LOADING_STATE.pending
            : LOADING_STATE.idle
      })
      .addCase(getAssignRadiologists.rejected, (state) => {
        state.radiologists.loadingState = LOADING_STATE.rejected
        state.groups.loadingState = LOADING_STATE.rejected
      })
      .addCase(getAssignRadiologists.fulfilled, (state, { payload }) => {
        const isSingleAssignment = isSingleOrderAssignment(payload.content)
        const currentTab: ASSIGNMENT_RADIOLOGIST_TAB =
          state.assignQuery.assignmentTab

        state.radiologists.loadingState = LOADING_STATE.fulfilled
        state.groups.loadingState = LOADING_STATE.rejected

        if (currentTab === ASSIGNMENT_RADIOLOGIST_TAB.RADIOLOGISTS) {
          state[currentTab].singleAssignmentList = isSingleAssignment
            ? mapSingleOrderAssignment(payload.content)
            : []
          state[currentTab].multiAssignmentList = isSingleAssignment
            ? []
            : payload.content
          state[currentTab].tableInfo = {
            ..._omit(payload, 'content'),
            isSingleOrder: isSingleAssignment,
          }
        } else {
          state[currentTab].radiologists = mapGroupsAssignment(payload.content)
          state[currentTab].tableInfo = { ..._omit(payload, 'content') }
        }
      })
      .addCase(assignOrdersRequest.fulfilled, (state) => {
        state.assignQuery.accessionNumbers = []
        state.assignQuery.isReassign = false
      })
      .addCase(linkAndLockOrdersRequest.pending, (state) => {
        state.lockInformation.isLockInProgress = true
      })
      .addCase(linkAndLockOrdersRequest.rejected, (state) => {
        state.lockInformation.isLockInProgress = false
      })
      .addCase(linkAndLockOrdersRequest.fulfilled, (state, { payload }) => {
        const { patients, ...restData } = payload

        state.lockInformation = {
          ...restData,
          patients: patients.reduce<Record<string, TLinkedOrderPatientInfo>>(
            (acc, item) => {
              if (item.id) {
                acc[item.id] = item
              }
              return acc
            },
            {}
          ),
          isLockInProgress: false,
        }
      })
      .addCase(unlockOrdersRequest.fulfilled, (state) => {
        state.lockInformation = initialState.lockInformation
      })
      .addCase(fetchOrdersFlags.pending, (state) => {
        state.flags.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchOrdersFlags.fulfilled, (state, { payload }) => {
        state.flags.list = payload
        state.flags.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(fetchOrdersFlags.rejected, (state) => {
        state.flags.loadingState = LOADING_STATE.rejected
      })
      .addCase(fetchOrdersReassignFlags.pending, (state) => {
        state.flags.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchOrdersReassignFlags.fulfilled, (state, { payload }) => {
        state.flags.list = payload
        state.flags.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(fetchOrdersReassignFlags.rejected, (state) => {
        state.flags.loadingState = LOADING_STATE.rejected
      })
      .addCase(fetchPreviouslyAssignedRadiologists.pending, (state) => {
        state.previouslyAssignedRadiologists.loadingState =
          LOADING_STATE.pending
      })
      .addCase(
        fetchPreviouslyAssignedRadiologists.fulfilled,
        (state, { payload }) => {
          state.previouslyAssignedRadiologists.list = payload
          state.previouslyAssignedRadiologists.loadingState =
            LOADING_STATE.fulfilled
        }
      )
      .addCase(fetchPreviouslyAssignedRadiologists.rejected, (state) => {
        state.previouslyAssignedRadiologists.loadingState =
          LOADING_STATE.rejected
      })
  },
})

export const {
  resetProviderAssignmentTableInfo,
  changeAssignTableQuery,
  changeAssignmentQuery,
  resetOrdersFlags,
  updateAssignQuery,
  resetPreviouslyAssignedRadiologists,
  setPreviouslyAssignedRadiologistsPageable,
  completeFlagLocally,
  completeAllFlagsLocally,
} = assignRadiologistsTableSlice.actions

export default assignRadiologistsTableSlice
