import { createSlice } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import * as controllers from './controllers'
import { IState } from './types'

const initialState: IState = {
  tagsTable: {
    list: {
      content: [],
      totalElements: 0,
      totalPages: 0,
      first: true,
      last: false,
    },
    loadingState: LOADING_STATE.idle,
    error: null,
  },
  allTags: {
    loadingState: LOADING_STATE.idle,
    list: [],
  },
}

export const name = 'tags'

const tagsSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetAllTags: (state) => {
      state.allTags = initialState.allTags
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(controllers.fetchTagArchive.pending, (state) => {
        state.tagsTable.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchTagArchive.fulfilled, (state) => {
        state.tagsTable.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchTagArchive.rejected, (state, action) => {
        state.tagsTable.loadingState = LOADING_STATE.rejected
        state.tagsTable.error = action.payload
      })
    builder
      .addCase(controllers.fetchTags.pending, (state) => {
        state.tagsTable.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchTags.fulfilled, (state, action) => {
        state.tagsTable.list = action.payload
        state.tagsTable.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchTags.rejected, (state, action) => {
        state.tagsTable.loadingState = LOADING_STATE.rejected
        state.tagsTable.error = action.payload
      })
    builder
      .addCase(controllers.fetchAllTags.pending, (state) => {
        state.allTags.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchAllTags.fulfilled, (state, action) => {
        state.allTags.list = action.payload
        state.allTags.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(controllers.fetchAllTags.rejected, (state, action) => {
        state.allTags.loadingState = LOADING_STATE.rejected
        state.allTags.error = action.payload
      })
  },
})

export const { resetAllTags } = tagsSlice.actions

export default tagsSlice
