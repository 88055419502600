export const Dashboard = {
  ordersInfo: 'Orders Info',
  billingInfo: 'Billing Info',
  supportRequests: 'Support Requests',
  pendingSubmission: 'Pending submission',
  inReview: 'In Review',
  undeliveredFinalReports: 'Undelivered Final Reports',
  pendingRevertBilling: 'Pending Revert Billing',
  pendingBillingInformation: 'Pending billing information',
  onHold: 'On Hold',
  allRequests: 'All requests',
  siteInfo: 'Site info',
  orders: 'Orders',
  ordersSupportRequests: 'Orders Support Requests',
}
