export const Patients = {
  // common
  patientListPageTitle: 'Patients',
  patientPageTitle: 'Patient Page',

  // patient page
  proScanMRNTitle: 'ProScan MRN: {{goldenResourceId}}',
  firstNameLabel: 'First Name',
  lastNameLabel: 'Last Name',
  dateOfBirthLabel: 'Date of Birth',
  genderAtBirthLabel: 'Gender at Birth',
  sourceMRN: 'Source MRN',
}
