/* eslint-disable */
// eslint wants to use single quotes for strings that contain single-quotes
// but prettier forces double quotes

export const common = {
  loading: 'Loading...',
  search: 'search',
  noOptions: 'No options',
  greetings: 'Hello,',
  pageWillBeDevelopedSoon: 'This page will be developed soon...',
  notFoundPageContent: 'Page not found',
  notFoundPageDescription:
    'The page you are looking for doesn’t exist or an another error occurred',
  accessDeniedPageDescription:
    'You don’t have required permission to see this page, please contact your system administrator',
  colorModeDark: 'Dark mode',
  colorModeLight: 'Light mode',
  enableNotifications: 'Enable notifications',
  enableNotificationdsDescription:
    'To perform this operation, please enable browser notifications in browser settings.',
  somethingWentWrong: 'Something went wrong',
  somethingWentWrongDescription:
    'We are working on the fix. Please, try again later.',
  tryAgain: 'try again later',
  myProfile: 'My Profile',
  logOut: 'Log Out',
  knowledgeBase: 'Knowledge Base',
  generalInfo: 'General Info',
  settings: 'Settings',
  decimalFieldValidation:
    '${label} should contain a valid decimal or integer value',
  addNewProvider: 'Add new provider',
  roles: {
    PRACTICE_USER: 'Practice User',
    SITE_ADMIN: 'Site Admin',
    SITE_USER: 'Site User',
    SUPER_USER: 'Super User',
    SYS_ADMIN: 'Sys Admin',
    PROCTOR: 'Proctor',
    PROVIDER: 'Provider',
    INDIVIDUAL: 'Individual',
  },
  BEErrors: {
    '00.00.00': 'System error',
    '01.00.00': 'System error in User-Management service',
    '01.00.01': 'Internal server error in User-Management service',
    '01.00.02': 'No such registered Keycloak user',
    '01.00.03': 'Roles mismatched with available roles in Keycloak',
    '01.00.04': 'Site ID field is not supported by these roles',
    '01.00.05': 'No permission for action',
    '01.00.06':
      'Roles that are present id database are not the same as in keycloak',
    '01.00.07': 'PACS User Name should be unique',
    '01.01.00': 'Unable to register new user in Keycloak',
    '01.01.01': 'Unable to log in to Keycloak with invalid credentials',
    '01.01.02': 'Internal exception in Keycloak',
    '01.01.03': 'User already exists in the system',
    '01.01.04': 'Request to keycloak was built incorrectly',
    '01.01.05': 'Login/password is incorrect',
    '01.01.06': 'The entered password is not valid',
    '01.00.08': 'User with such associated provider already exists',
    '02.00.00': 'System error',
    '02.00.01': 'Internal server error',
    '02.00.02': 'Company name already exists',
    '02.00.03': 'No such registered company',
    '02.00.04': 'Name should be unique',
    '02.00.05': 'No such registered site',
    '02.00.06': 'No site specified error',
    '02.00.07': 'Practice name already exists',
    '02.00.08': 'Validation error',
    '02.00.09': 'No permission for action',
    '02.00.10': 'No such registered practice',
    '02.00.11': 'No such billing type',
    '02.00.12': 'This Org code is already used in the system',
    '02.00.14': 'Required to select at least one urgency',
    '02.00.15': 'Invalid delivery report pattern',
    '02.01.00': 'File upload error',
    '03.00.00': 'System error',
    '03.00.01': 'Internal server error',
    '03.00.02': 'No such order found by given id',
    '03.00.03': 'No such file type',
    '03.00.04': 'Only 1 primary physician is accepted',
    '03.00.05': "Some of the given ndc code id doesn't exist",
    '03.00.06': 'CompanyId should be specified',
    '03.00.07': 'Given combination of country/state is not acceptable',
    '03.00.08': 'Such sort parameters are not supported',
    '03.00.09': 'No such procedure found by given id',
    '03.00.10': 'The procedure is in an invalid status for operation',
    '03.00.11': 'Invalid request',
    '03.00.12': 'Such procedure id already exists in the database',
    '03.00.13': 'Maximum icd codes count exceeded',
    '03.00.14':
      "Hold details shouldn't be empty when moving order to on hold status",
    '03.00.15':
      "Manual hold reasons can't be updated during order creation/update",
    '03.00.16': 'No such flag found by given id',
    '03.00.17': 'The flag is in an invalid status for operation',
    '03.00.18': 'Exam Information has missing procedure data',
    '03.00.19': 'Urgency phone must be specified',
    '03.00.20': "Urgency phone mustn't be specified",
    '03.00.21': 'Dicom/non dicom ID doesnt exist in the system',
    '03.00.22': 'Maximum ndc codes count exceeded',
    '03.00.23': 'No such point of service found by given id',
    '03.00.24': 'Admission date validation error',
    '03.00.25': 'Bad status for transition',
    '03.00.26': 'Already assigned error',
    '03.00.27': 'Procedure not matched',
    '03.00.28': 'No such order flag found by given ids',
    '03.00.29': 'The flag is in an invalid state for operation',
    '03.00.30': 'Required flags for an order in non-completed state',
    '03.00.31': 'Some of orders was locked by another user',
    '03.00.32': 'No such patient found by given id',
    '03.00.33': 'Report Type should be final',
    '03.00.35': 'Tag with such name and type already exists',
    '03.00.36': 'No such tag found by given id',
    '03.00.37': "User don't have enough rights",
    '03.00.38': 'Wrong color format',
    '03.00.39': 'Archived tag cannot be updated',
    '03.00.40': 'Order does not contain a patient',
    '03.00.51': 'Comparison section is invalid',
    '03.00.52': 'Required images / documents for interpretation are missed',
    '03.00.60':
      'You are not allowed to cancel the order at this stage Contact your Super admin to proceed with order cancelation',
    '03.00.63': 'Such order is not available for this user',
    '03.00.72': 'Unsupported billing type',
    '03.00.75': 'Unable to save submitted order as draft',
    '03.00.77':
      'Please specify the Payer ID to move the billing to “Billing Info Verified” status.',
    '03.00.78':
      'Order creation is currently disabled. Please contact your administrator for more details.',
    '03.00.80': 'DICOM transfer is already in progress.',
    '03.01.00': 'No active task',
    '04.00.00': 'System error',
    '04.00.01': 'Internal server error',
    '04.00.02': 'Radiologist from USA should have at least 1 US license',
    '04.00.03': 'Given medical licenses list contains duplicates',
    '04.00.04': 'Given medical license already exists in database',
    '04.00.05': "Medical license's issued date is not acceptable",
    '04.00.06': "Medical license's expiration date is not acceptable",
    '04.00.07': 'No radiologist data',
    '04.00.08': 'Wrong npi format',
    '04.00.09': 'USA country is required for state filtering',
    '04.00.10': 'Invalid provider id',
    '04.00.11': 'Such sort parameters are not supported',
    '04.00.12': 'This association is only supported for physician',
    '04.00.13': 'This association for given physician already exists',
    '04.00.14': 'Given ids do not refer to any provider entity',
    '04.00.15':
      "Radiologist can't be preferred and not preferred at the same time",
    '04.00.16': 'Invalid third party catalog id',
    '04.00.17': 'No physician data',
    '04.00.18': 'No permission for action',
    '04.00.19': 'Invalid request body',
    '04.00.20': 'Given npi already exists in database',
    '04.00.24':
      'Radiologists Group cannot be saved. On-Call Radiologists Group already exist in the system. Please uncheck the On-Call Radiologists Group checkbox to save changes successfully.',
    '04.01.00': 'Request to NPI registry failed',
    '05.00.00': 'System error',
    '05.00.01': 'Internal server error',
    '05.00.02': 'The date of birth cannot be set for tomorrow',
    '05.00.03':
      'The date of birth cannot be set earlier than the following date: 01/01/1900',
    '05.00.04': 'No such patient found by given id2',
    '05.00.05': 'MRN already exists in the company',
    '05.00.06': 'Bad request error',
    '05.00.07': 'Unable to parse given HL7 message',
    '05.00.08': 'No such patient found',
    '05.00.09': "Specified patient doesn't have golden record",
    '05.01.00': 'Failed to receive response from hapi fhir service',
    '06.00.00': 'System error',
    '06.00.01': 'Internal server error',
    '06.00.02': 'No permission for action',
    '06.00.03': 'No such associated site IDs for site admin',
    '06.00.04': 'No such associated practice IDs for practice admin',
    '06.00.05': 'Wrong provider for assignment',
    '06.00.09': 'Number of orders exceeds the limit',
    '06.01.00': 'User service unreachable error',
    '06.02.00': 'Organisation service unreachable error',
    '06.03.00': 'Order service unreachable error',
    '06.04.00': 'Provider service unreachable error',
    '06.05.00': 'Patient service unreachable error',
    '06.12.00': 'Paperwork service unreachable error',
    '06.77.00': 'Downstream service unhandled error',
    '07.00.00': 'System error',
    '07.00.01': 'Internal server error',
    '07.00.02': 'No permission for action',
    '07.00.03': 'Report template for given site already exists',
    '07.00.04': 'Demographic data is invalid',
    '07.00.05': 'Amazon S3 error',
    '07.00.06': 'No template for site',
    '07.00.07': 'Mismatched provider type',
    '07.00.08': 'Order is in an illegal state',
    '07.00.09': 'Invalid report message',
    '07.00.10': 'Unsupported report message state',
    '07.00.11': 'Provider is in an illegal state',
    '07.00.12': 'Missing demographics data',
    '07.00.13': 'No such report',
    '07.00.14': 'Report is in an illegal state',
    '07.06.00': 'File management service error',
    '07.04.01': 'Provider management service error',
    '07.03.02': 'Order management service error',
    '08.00.00': 'System error',
    '08.00.01': 'Internal server error',
    '08.00.02': 'Invalid file format',
    '08.00.03': 'Not existed key error',
    '08.00.04': 'No permission for action',
    '08.00.05': 'Unable to split given pdf to pages',
    '08.01.00': 'S3 operation error',
    '09.00.01': 'Internal server error',
    '09.00.02': 'No such paperwork exists',
    '09.00.03': 'Validation rules were violated',
    '10.00.01': 'Internal server error',
    '10.00.03': 'No such DICOM destination exists by the given ID',
    '10.00.04': "It's impossible to remove active DICOM Destination",
    '10.00.05':
      'Entity is currently linked to other elements in the system. Please ensure all dependencies are removed before proceeding',
    '10.00.07': 'A name should be unique',
    '10.00.09': 'Server group does not exist',
    '10.00.11':
      'Entity is currently linked to other elements in the system. Please ensure all dependencies are removed before proceeding',
    '10.00.12': 'A name should be unique',
    '11.00.01': 'Internal server error',
    '11.00.03': 'Invalid request error',
    '11.00.05': 'No such support request error',
    '15.00.01': 'Download is already in progress',
    '0': 'Internal server error',
    ERR_CANCELED: 'Error canceled',
    BROWSER_API_NOTIFICATION_DISABLED: 'Browser API notification disabled',
  },
  tooltips: {
    notificationsEnabled:
      'To disable notifications you need to disable them in browser settings and reload the page ',
    notificationsDisabled:
      'To allow notifications you need to allow them in browser settings and reload the page ',
    manuallyRemoveNotifications:
      'If you want to turn off browser notifications for this application, you need to change permissions in your browser or system configurations',
  },
  statuses: {
    active: 'Active',
    archived: 'Archived',
    copied: 'Copied',
    resolved: 'Resolved',
    failed: 'Failed',
  },
  errors: {
    warning: 'Warning!',
    filesSizeTooBig:
      'Files can not be uploaded. Total size of all uploaded files cannot exceed {{totalMaxSizeMb}} MB',
    filesExtensionError:
      'Files can not be uploaded. Uploaded files should be in {{ availableFileFormats }} format',
    totalStudiesSizeExceeded:
      'Files can not be uploaded. Total size of all uploaded studies cannot be more than {{totalMaxSize}} {{unit}}',
    studyTransactionSizeExceeded:
      'Files can not be uploaded. Total size of a single upload transaction can not be more than {{maxTransactionSize}}{{unit}}',
    tabsRequiredInfo_one:
      'Fill out all required information in {{errorTabs}} section',
    tabsRequiredInfo_other:
      'Fill out all required information in {{errorTabs}} sections',
    patientRequired: 'Patient is required',
    fileUploadError: 'File upload error',
    deletionFailed: 'Deletion failed ',
    selectAtLeastOnePhoneNumber: 'Select at least one phone number',
  },
  warnings: {
    attention: 'Attention!',
    selectAnOptionWarning: 'Select an option',
  },
  validation: {
    minArray_one: '{{label}} field must have at least {{count}} item',
    minArray_other: '{{label}} field must have at least {{count}} items',
    onlyAlphanumericAmpAndDashIsAllowed:
      'Only numbers, letters, - and & are allowed',
    onlyAlphanumericAndDashIsAllowed: 'Only numbers, letters and - are allowed',
    onlyAlphanumericAmpAndUnderscoreIsAllowed:
      'Only numbers, letters, and _ are allowed',
    onlyNumericAndDashIsAllowed: 'Only numbers and - are allowed',
    onlyNumericIsAllowed: 'Only numbers are allowed',
    onlyAlphabetIsAllowed: 'Only letters are allowed',
    onlyAlphabetAndNumericIsAllowed: 'Only letters and numbers are allowed',
    onlyAlphanumericAmpDashSpacesAreAllowed:
      'Only numbers, letters, -, & and spaces are allowed',
    validationCharsMax: 'Maximum {{max}} characters required.',
    validationCharsMin: 'Minimum {{min}} characters required.',
    validationNonSupportedChars: 'Non supported characters provided',
    validationDateNotEarlierThan1900:
      '{{fieldName}} must be not earlier than 01/01/1900.',
    validationDateNotLaterThanToday:
      '{{fieldName}} must be not later than today.',
    validationDateNotEarlierThanToday:
      '{{fieldName}} must be not earlier than today.',
    validationTimeToRangeError:
      '${label} must be not earlier than {{timeFrom}} field value.',
    validationTimeFromRangeError:
      '${label} must be not later than {{timeTo}} field value.',
    validationInvalidDate: '${label} must be in MM/DD/YYYY format.',
    validationInvalidTime: '${label} must be in HH:MM AM/PM format',
    validationUniqueCompaniesAndSite: 'Name should be unique',
    invalidFormat: 'Invalid format',
    invalidDeliveryReportPattern: 'Invalid delivery report pattern',
    validationUniqueFieldWithArticle: 'A {{field}} should be unique',
    validationUniqueField: '{{field}} should be unique',
    fieldNameIsRequired: '{{fieldName}} is a required field',
    requiredFieldWithYupLabel: '${label} is a required field',
    invalidEmailFormat: 'Email format is invalid',
    validationCommaSeparated: 'Should be comma separated',
  },
  PSSnackbar: {
    error: 'Error',
    success: 'Success!',
    changesSuccessfullySaved: 'Changes are successfully saved',
  },
  undoSnackbar: {
    deleted: 'Deleted',
    undo: 'Undo',
  },
  notifications: {
    errorHeader: 'Error',
    corruptedDicom: 'Some DICOM files might be corrupted or incomplete.',
    rejectedNotificationMessage:
      'Changes have not been saved. Please try again later',
    generalErrorNotificationMessage:
      'Something went wrong. Please try again later.',
    excludedFiles: `Some of the uploaded files may be empty (0 bytes) or have an unsupported format. Please ensure your files are not empty and their formats are one of the following: {{availableFormats}}.`,
  },
  dateFormats: {
    ddMMyyyy: 'DD/MM/YYYY',
    MMddyyyy: 'MM/DD/YYYY',
    yyyyMMdd: 'YYYY/MM/DD',
    yyyyddMM: 'YYYY/DD/MM',
    HHMMamPm: 'HH:MM (AM/PM)',
  },
}
