import { createAsyncThunk } from '@reduxjs/toolkit'
import request from 'utils/api/request'

import { DASHBOARD_SLICE_NAME } from './constants'
import { TAdminDashboardRs, TBffCompanyDashboardContentRs } from './types'

export const fetchDashboardInfo =
  createAsyncThunk<TBffCompanyDashboardContentRs>(
    `${DASHBOARD_SLICE_NAME}/fetchDashboardInfo`,
    async (_, { rejectWithValue }) => {
      const result = await request<TBffCompanyDashboardContentRs>({
        url: '/bff/companies/dashboard',
        method: 'GET',
      })
      if (result.error) {
        return rejectWithValue(result.error)
      }

      return result.data
    }
  )

export const fetchSuperUserDashboardInfo = createAsyncThunk<TAdminDashboardRs>(
  `${DASHBOARD_SLICE_NAME}/fetchSuperUserDashboardInfo`,
  async (_, { rejectWithValue }) => {
    const result = await request<TAdminDashboardRs>({
      url: '/bff/dashboards/admin',
      method: 'GET',
    })
    if (result.error) {
      return rejectWithValue(result.error)
    }

    return result.data
  }
)
