import { SORT_DIRECTIONS } from 'constants/tables'

export const switchTableColumnSort = (columnKey: string, sort: string = '') => {
  const [currentColumnKey, currentSortDirection] = sort.split(',')

  if (currentColumnKey !== columnKey) {
    return `${columnKey},${SORT_DIRECTIONS.ASC}`
  }

  switch (currentSortDirection) {
    case SORT_DIRECTIONS.ASC:
      return `${currentColumnKey},${SORT_DIRECTIONS.DESC}`
    case SORT_DIRECTIONS.DESC:
      return ''
    default:
      return `${currentColumnKey},${SORT_DIRECTIONS.ASC}`
  }
}
