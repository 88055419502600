import type { IState } from 'store/dashboard/types'
import { LOADING_STATE } from 'types/general'

export const initialState: IState = {
  loadingState: LOADING_STATE.idle,
  info: undefined,
  adminInfo: undefined,
}

export const DASHBOARD_SLICE_NAME = 'dashboard'
