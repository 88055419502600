import { TOrderStatus } from 'store/flags/types'
import { LOADING_STATE, TPageable } from 'types/general'
import { components } from 'types/schemas'

export enum ASSIGNMENT_RADIOLOGIST_TAB {
  RADIOLOGISTS = 'radiologists',
  GROUPS = 'groups',
}

export enum STATUS_ICON {
  CHECK = 'check',
  CROSS = 'cross',
}

type TBffRadiologistAssignmentPageRs =
  components['schemas']['BffRadiologistAssignmentPageRs']
export type TProviderDisplayName = Required<
  components['schemas']['BffRadiologistAssignmentGridRs']['providerDisplayNameRs']
>

export type TUnreadUrgency = Required<components['schemas']['Urgency']>
export type TUnreadStatus = Required<components['schemas']['Status']>
export type TUnreadForTomorrow = {
  urgencies: TUnreadUrgency[]
  status: TUnreadStatus | null
}
export type TUnreadForToday = {
  urgencies: TUnreadUrgency[]
  statuses: TUnreadStatus[]
}
export type TUnreadAssignmentCell = Omit<
  components['schemas']['OrderUnreadDataRs'],
  'today' | 'tomorrow'
> & {
  today: TUnreadForToday
  tomorrow: TUnreadForTomorrow
}
export type TOrderData = components['schemas']['BffOrderDataRs'] & {
  orderId: string
}

export type TOrderFlag = components['schemas']['BffOrderFlagRs']
export type TBffCombinedOrderFlagRs =
  components['schemas']['BffCombinedOrderFlagRs']

export type TMultiAssignmentRadiologist = {
  providerDisplayNameRs: TProviderDisplayName
  orderData: TOrderData[]
}
export type TRadiologistsTableResp = Omit<
  TBffRadiologistAssignmentPageRs,
  'content'
>
export type TAssignmentRadiologistResponse = TRadiologistsTableResp & {
  content: TMultiAssignmentRadiologist[]
}

export interface IAssignmentOrders {
  accessionNumbers: string
  isReassignmentFlow: boolean
}

export type TLockOrdersResponse = Omit<
  components['schemas']['OrderLockRs'],
  'linkedOrders'
> & {
  linkedOrders: TBffLinkedOrderItem[]
}

export type TAssignOrdersRequest = Omit<
  components['schemas']['AssignmentRq'],
  'orderIds'
>

export type TLinkedOrderPatientInfo =
  components['schemas']['LinkedOrderPatientInfo']

export type TRadiologistsTableInfo = TRadiologistsTableResp & {
  isSingleOrder: boolean
}
type TRadiologistDegree = {
  id?: number
  name?: string
}

export type TBffLinkedOrderItem = Omit<
  components['schemas']['BffLinkedOrderItem'],
  'accessionNumber' | 'orderStatus'
> & {
  accessionNumber: string
  orderStatus: TOrderStatus
}

type TRadiologistInfo = {
  providerId: string
  firstName: string
  lastName: string
  degrees: TRadiologistDegree[]
}
export type TSingleAssignmentRadiologist = {
  providerId: string
  radiologistName: TRadiologistInfo
  prefPanel?: TOrderData['prefPanel']
  isPriviledge?: TOrderData['isPriviledge']
  isLicenseMatched?: TOrderData['isLicenseMatched']
  isSubspecialtyMatched?: TOrderData['isSubspecialtyMatched']
  rvuLeft?: TOrderData['rvuLeft']
  rvuTarget?: TOrderData['rvuTarget']
}

export type TGroupAssignment = {
  providerId: string
  radiologistName: TRadiologistInfo
  rvuLeft?: TOrderData['rvuLeft']
  rvuTarget?: TOrderData['rvuTarget']
}

export interface ITabQueryData {
  page: number
  size: number
}

export type TLockInformation = TLockOrdersResponse & {
  isLockInProgress: boolean
}

export interface IQueryData extends ITabQueryData {
  includeDoNotAssignmentPanel: boolean
  accessionNumbers: string[]
  isGroup: boolean
}

export type TBffHistoricalOrdersPageRs =
  components['schemas']['BffHistoricalOrdersPageRs']

export interface IState {
  flags: {
    list: TBffCombinedOrderFlagRs[]
    loadingState: LOADING_STATE
  }
  lockInformation: Omit<TLockInformation, 'patients'> & {
    patients: Record<string, TLinkedOrderPatientInfo>
  }
  previouslyAssignedRadiologists: {
    pageable: TPageable
    loadingState: LOADING_STATE
    list?: TBffHistoricalOrdersPageRs
  }
  radiologists: {
    loadingState: LOADING_STATE
    queryData: ITabQueryData
    tableInfo: TRadiologistsTableInfo
    singleAssignmentList: TSingleAssignmentRadiologist[]
    multiAssignmentList: TMultiAssignmentRadiologist[]
  }
  groups: {
    loadingState: LOADING_STATE
    queryData: ITabQueryData
    tableInfo: TRadiologistsTableResp
    radiologists: TGroupAssignment[]
  }
  assignQuery: {
    assignmentTab: ASSIGNMENT_RADIOLOGIST_TAB
    accessionNumbers: string[]
    isReassign?: boolean
    includeDoNotAssignmentPanel: boolean
  }
}
