import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { LOADING_STATE } from 'types/general'

import { INITIAL_STATE, PROVIDERS_SLICE_NAME } from './constants'
import {
  checkProviderNpi,
  checkProviderNpiForSite,
  createOnCallAssignment,
  deleteOnCallAssignmentById,
  editOnCallAssignmentById,
  fetchProvider,
  fetchRadiologistsFilterData,
  getOnCallAssignmentList,
  getProviderFaxesRq,
  sendProviderFaxesRq,
} from './controllers'

const providersSlice = createSlice({
  name: PROVIDERS_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    resetCheckProvider: (state) => {
      state.checkProvider = INITIAL_STATE.checkProvider
    },
    resetProviderInfo: (state) => {
      state.providerInfo = INITIAL_STATE.providerInfo
    },
    changeAssignmentTablePage: (state, { payload }) => {
      state.onCallAssignment.query = {
        ...state.onCallAssignment.query,
        page: payload.page,
      }
    },
    changeAssignmentTableSize: (state, { payload }) => {
      state.onCallAssignment.query = {
        page: payload.page,
        size: payload.size,
      }
    },
    setEditAssignmentId: (state, { payload }) => {
      state.onCallAssignment.editAssignmentId = payload.id
    },
    resetEditAssignmentId: (state) => {
      state.onCallAssignment.editAssignmentId =
        INITIAL_STATE.onCallAssignment.editAssignmentId
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProvider.pending, (state) => {
        state.loadingState = LOADING_STATE.pending
      })
      .addCase(fetchProvider.fulfilled, (state, action) => {
        state.providerInfo = action.payload
        state.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(fetchProvider.rejected, (state, action) => {
        state.error = action.payload
        state.loadingState = LOADING_STATE.rejected
      })
      .addCase(fetchRadiologistsFilterData.fulfilled, (state, { payload }) => {
        state.radiologistsFilterData = payload
      })
      .addCase(getOnCallAssignmentList.pending, (state) => {
        state.onCallAssignment.loadingState = LOADING_STATE.pending
      })
      .addCase(getOnCallAssignmentList.rejected, (state) => {
        state.onCallAssignment.loadingState = LOADING_STATE.rejected
      })
      .addCase(getOnCallAssignmentList.fulfilled, (state, { payload }) => {
        state.onCallAssignment.table = payload
        state.onCallAssignment.loadingState = LOADING_STATE.fulfilled
      })
      .addCase(getProviderFaxesRq.pending, (state) => {
        state.providerFaxes.loadingState = LOADING_STATE.pending
      })
      .addCase(getProviderFaxesRq.fulfilled, (state, { payload }) => {
        state.providerFaxes.loadingState = LOADING_STATE.fulfilled
        state.providerFaxes.orderFaxes = payload
      })
      .addCase(getProviderFaxesRq.rejected, (state) => {
        state.providerFaxes.loadingState = LOADING_STATE.rejected
      })
      .addCase(sendProviderFaxesRq.pending, (state) => {
        state.providerFaxes.isSubmitting = true
      })
      .addCase(sendProviderFaxesRq.fulfilled, (state) => {
        state.providerFaxes.isSubmitting = false
      })
      .addCase(sendProviderFaxesRq.rejected, (state) => {
        state.providerFaxes.isSubmitting = false
      })
      .addMatcher(
        isAnyOf(checkProviderNpi.pending, checkProviderNpiForSite.pending),
        (state) => {
          state.checkProvider.loadingState = LOADING_STATE.pending
          state.checkProvider.data = undefined
          state.checkProvider.error = null
        }
      )
      .addMatcher(
        isAnyOf(checkProviderNpi.fulfilled, checkProviderNpiForSite.fulfilled),
        (state, { payload }) => {
          state.checkProvider.loadingState = LOADING_STATE.fulfilled
          state.checkProvider.data = payload
          state.checkProvider.error = null
        }
      )
      .addMatcher(
        isAnyOf(checkProviderNpi.rejected, checkProviderNpiForSite.rejected),
        (state, { payload }) => {
          state.checkProvider.loadingState = LOADING_STATE.rejected
          state.checkProvider.error = payload
        }
      )
      .addMatcher(
        isAnyOf(
          createOnCallAssignment.pending,
          editOnCallAssignmentById.pending,
          deleteOnCallAssignmentById.pending
        ),
        (state) => {
          state.onCallAssignment.isFormLoading = true
        }
      )
      .addMatcher(
        isAnyOf(
          createOnCallAssignment.fulfilled,
          editOnCallAssignmentById.fulfilled,
          deleteOnCallAssignmentById.fulfilled,
          createOnCallAssignment.rejected,
          editOnCallAssignmentById.rejected,
          deleteOnCallAssignmentById.rejected
        ),
        (state) => {
          state.onCallAssignment.isFormLoading = false
        }
      )
  },
})

export const {
  changeAssignmentTablePage,
  changeAssignmentTableSize,
  resetCheckProvider,
  resetProviderInfo,
  setEditAssignmentId,
  resetEditAssignmentId,
} = providersSlice.actions

export default providersSlice
