import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getPatientOptionLabel } from 'pages/orders/OrdersList/helpers/getPatientOptionLabel'
import { TPatientResponse } from 'store/patient/types'

interface IPatientCellProps {
  data: Partial<TPatientResponse> | null
}

export const PatientCell: FC<IPatientCellProps> = ({ data }) => {
  const { t } = useTranslation()
  const firstname = data?.firstname
  const lastname = data?.lastname

  return (
    <>
      {firstname || lastname
        ? getPatientOptionLabel(firstname, lastname)
        : t('components.table.notApplicable')}
    </>
  )
}
