import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  PRACTICE_INITIAL_STATE,
  PRACTICE_SLICE_NAME,
} from 'store/practice/constants'
import { LOADING_STATE } from 'types/general'

import * as controllers from './controllers'

const practicesSlice = createSlice({
  name: PRACTICE_SLICE_NAME,
  initialState: PRACTICE_INITIAL_STATE,
  reducers: {
    resetPracticePageData: (state) => {
      state.practicePage = PRACTICE_INITIAL_STATE.practicePage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(controllers.fetchPractices.pending, (state) => {
        state.practicesPage.tableData.loadingState = LOADING_STATE.pending
      })
      .addCase(controllers.fetchPractices.fulfilled, (state, action) => {
        state.practicesPage.tableData.totalElements =
          action.payload.totalElements
        state.practicesPage.tableData.totalPages = action.payload.totalPages
        state.practicesPage.tableData.practices = action.payload.content
        state.practicesPage.tableData.loadingState = LOADING_STATE.fulfilled
        state.practicesPage.tableData.isFirstPage = Boolean(
          action.payload.first
        )
        state.practicesPage.tableData.isLastPage = Boolean(action.payload.last)
      })
      .addCase(
        controllers.fetchPracticesFiltersOptions.fulfilled,
        (state, action) => {
          state.practicesPage.filtersOptions = action.payload
        }
      )
      .addCase(controllers.fetchAllPractices.fulfilled, (state, action) => {
        state.practicesCatalog = action.payload
      })
      .addMatcher(
        isAnyOf(
          controllers.fetchPractice.pending,
          controllers.createPractice.pending,
          controllers.updatePractice.pending
        ),
        (state) => {
          state.practicePage.loadingState = LOADING_STATE.pending
          state.practicePage.error = undefined
        }
      )
      .addMatcher(
        isAnyOf(
          controllers.fetchPractice.rejected,
          controllers.createPractice.rejected,
          controllers.updatePractice.rejected
        ),
        (state, action) => {
          state.practicePage.loadingState = LOADING_STATE.rejected
          state.practicePage.error = action.payload
        }
      )
      .addMatcher(
        isAnyOf(
          controllers.fetchPractice.fulfilled,
          controllers.createPractice.fulfilled,
          controllers.updatePractice.fulfilled
        ),
        (state) => {
          state.practicePage.loadingState = LOADING_STATE.fulfilled
          state.practicePage.error = undefined
        }
      )
  },
})

export const { resetPracticePageData } = practicesSlice.actions
export default practicesSlice
