import { TOrdersTableParams } from 'store/filters/orders/types'
import { prepareArrayForSending } from 'utils/helpers/arrays/prepareArrayForSending'

export const prepareTableQueryParamsForSend = (
  searchParams: TOrdersTableParams
) => {
  return {
    ...(searchParams.urgencies && {
      urgencies: prepareArrayForSending(searchParams.urgencies),
    }),
    ...(searchParams.siteIds && {
      siteIds: prepareArrayForSending(searchParams.siteIds),
    }),
    ...(searchParams.tagIds && {
      tagIds: prepareArrayForSending(searchParams.tagIds),
    }),
    ...(searchParams.systemTagIds && {
      systemTagIds: prepareArrayForSending(searchParams.systemTagIds),
    }),
    ...(searchParams.holdReasons && {
      holdReasons: prepareArrayForSending(searchParams.holdReasons),
    }),
    ...(searchParams.states && {
      states: prepareArrayForSending(searchParams.states),
    }),
    ...(searchParams.modalityIds && {
      modalityIds: prepareArrayForSending(searchParams.modalityIds),
    }),
    ...(searchParams.bodyPartIds && {
      bodyPartIds: prepareArrayForSending(searchParams.bodyPartIds),
    }),
    ...(searchParams.procedureIds && {
      procedureIds: prepareArrayForSending(searchParams.procedureIds),
    }),
    ...(searchParams.cptCodeIds && {
      cptCodeIds: prepareArrayForSending(searchParams.cptCodeIds),
    }),
    ...(searchParams.radiologistIds && {
      radiologistIds: prepareArrayForSending(searchParams.radiologistIds),
    }),
    ...(searchParams.contributorReaderIds && {
      contributorReaderIds: prepareArrayForSending(
        searchParams.contributorReaderIds
      ),
    }),
    ...(searchParams.physicianIds && {
      physicianIds: prepareArrayForSending(searchParams.physicianIds),
    }),
    ...(searchParams.companyIds && {
      companyIds: prepareArrayForSending(searchParams.companyIds),
    }),
    ...(searchParams.billingTypeIds && {
      billingTypeIds: prepareArrayForSending(searchParams.billingTypeIds),
    }),
    ...(searchParams.billingStatuses && {
      billingStatuses: prepareArrayForSending(searchParams.billingStatuses),
    }),
    ...(searchParams.reportQaReasons && {
      reportQaReasons: prepareArrayForSending(searchParams.reportQaReasons),
    }),
    ...(searchParams.insuranceGroupNames && {
      insuranceGroupNames: prepareArrayForSending(
        searchParams.insuranceGroupNames
      ),
    }),
    ...(searchParams.orderStatuses && {
      orderStatuses: prepareArrayForSending(searchParams.orderStatuses),
    }),
    ...(searchParams.patientIds && {
      patientIds: prepareArrayForSending(searchParams.patientIds),
    }),
    ...(searchParams.proscanMrnSearch && {
      proscanMrnSearch: searchParams.proscanMrnSearch,
    }),
    ...(searchParams?.patientSearch && {
      patientSearch: searchParams.patientSearch,
    }),
    ...(searchParams?.siteSearch && {
      siteSearch: searchParams.siteSearch,
    }),
    ...(searchParams.proscanAccessionSearch && {
      proscanAccessionSearch: searchParams.proscanAccessionSearch,
    }),
    ...(searchParams.sourceMrnSearch && {
      sourceMrnSearch: searchParams.sourceMrnSearch,
    }),
    ...(searchParams.sourceAccessionSearch && {
      sourceAccessionSearch: searchParams.sourceAccessionSearch,
    }),
    ...(searchParams.hideInReview && {
      hideInReview: searchParams.hideInReview,
    }),
    isInactiveStatuses: searchParams.isInactiveStatuses,
    dateOfServiceFrom: searchParams.dateOfServiceFrom,
    dateOfServiceTo: searchParams.dateOfServiceTo,
    patientDayOfBirth: searchParams.patientDayOfBirth,
    followUpFrom: searchParams.followUpFrom,
    followUpTo: searchParams.followUpTo,
    hasAssignedRadiologist: searchParams.hasAssignedRadiologist,
    orderStatus: searchParams.orderStatus,
    ...(searchParams.sort && { sort: searchParams.sort }),
  }
}
