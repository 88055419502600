import { filtersReducer } from 'store/filters/slice'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { PROVIDERS_TABLE_NAMES_ENUM } from 'store/providers/types'
import { RootState } from 'store/store'

export const getCompany = (state: RootState) => state.company

export const getCompanyListLoadingState = (state: RootState) =>
  getCompany(state).loadingState

export const getSupportTableConfiguration = filtersReducer.selector(
  (combinedState) => combinedState.supportFilters,
  (rootState: RootState) => rootState.filters
)

export const getOrderTableConfiguration = filtersReducer.selector(
  (combinedState, name: ORDERS_TABLE_TAB) => combinedState.ordersFilters[name],
  (rootState: RootState) => rootState.filters
)

export const getAllOrderTableConfiguration = filtersReducer.selector(
  (combinedState) => combinedState.ordersFilters[ORDERS_TABLE_TAB.ALL],
  (rootState: RootState) => rootState.filters
)

export const getAssignmentOrderTableConfiguration = filtersReducer.selector(
  (combinedState) => combinedState.ordersFilters[ORDERS_TABLE_TAB.ASSIGNMENT],
  (rootState: RootState) => rootState.filters
)

export const getBillingOrderTableConfiguration = filtersReducer.selector(
  (combinedState) => combinedState.ordersFilters[ORDERS_TABLE_TAB.BILLING],
  (rootState: RootState) => rootState.filters
)

export const getPreparationOrderTableConfiguration = filtersReducer.selector(
  (combinedState) => combinedState.ordersFilters[ORDERS_TABLE_TAB.PREPARATION],
  (rootState: RootState) => rootState.filters
)

export const getReportQAOrderTableConfiguration = filtersReducer.selector(
  (combinedState) => combinedState.ordersFilters[ORDERS_TABLE_TAB.REPORT_QA],
  (rootState: RootState) => rootState.filters
)

export const getRadiologistTableConfiguration = filtersReducer.selector(
  (combinedState) =>
    combinedState.providersFilters[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS]
      .columnsConfiguration,
  (rootState: RootState) => rootState.filters
)

export const getPhysiciansTableConfiguration = filtersReducer.selector(
  (combinedState) =>
    combinedState.providersFilters[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS]
      .columnsConfiguration,
  (rootState: RootState) => rootState.filters
)

export const getRadiologistTableFiltersAndQueryData = filtersReducer.selector(
  (combinedState) =>
    combinedState.providersFilters[PROVIDERS_TABLE_NAMES_ENUM.RADIOLOGISTS],
  (rootState: RootState) => rootState.filters
)

export const getPhysicianTableFiltersAndQueryData = filtersReducer.selector(
  (combinedState) =>
    combinedState.providersFilters[PROVIDERS_TABLE_NAMES_ENUM.PHYSICIANS],
  (rootState: RootState) => rootState.filters
)

export const getUsersTableFiltersAndQueryData = filtersReducer.selector(
  (combinedState) => combinedState.usersFilters,
  (rootState: RootState) => rootState.filters
)

export const getCompanyTableFiltersAndQueryData = filtersReducer.selector(
  (combinedState) => combinedState.companyFilters,
  (rootState: RootState) => rootState.filters
)

export const getPracticeTableFiltersAndQueryData = filtersReducer.selector(
  (combinedState) => combinedState.practiceFilters,
  (rootState: RootState) => rootState.filters
)

export const getFlagsTableFiltersAndQueryData = filtersReducer.selector(
  (combinedState) => combinedState.flagsFilters,
  (rootState: RootState) => rootState.filters
)

export const getTagsTableFiltersAndQueryData = filtersReducer.selector(
  (combinedState) => combinedState.tagsFilters,
  (rootState: RootState) => rootState.filters
)

export const getDestinationsGroupTableQueryData = filtersReducer.selector(
  (combinedState) =>
    combinedState.destinationsFilters.destinationGroupsTableData,
  (rootState: RootState) => rootState.filters
)

export const getDestinationsTableQueryData = filtersReducer.selector(
  (combinedState) => combinedState.destinationsFilters.destinationsTableData,
  (rootState: RootState) => rootState.filters
)

export const getProceduresTableFiltersAndQueryData = filtersReducer.selector(
  (combinedState) => combinedState.proceduresFilters,
  (rootState: RootState) => rootState.filters
)
