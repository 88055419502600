import { TServiceError } from 'utils/api/types'
import { LOADING_STATE } from 'types/general'
import { components } from 'types/schemas'

export type TTagsPageRs = Omit<
  Required<components['schemas']['TagPageRs']>,
  'content'
> & { content: TTagRs[] }

export type TTagRs = Required<
  Omit<Required<components['schemas']['TagGridRs']>, 'type' | 'status'> & {
    type: TAG_TYPE
    status: TAG_STATUS
  }
>

export type TTagsFilter = {
  search?: string
  statuses: TAG_STATUS[]
}

export enum TAG_STATUS {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum TAG_TYPE {
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
}

export type TGroupedTagsByType = Record<TAG_TYPE, TTagRs[]>

export interface IState {
  tagsTable: {
    list: TTagsPageRs
    loadingState: LOADING_STATE
    error?: TServiceError | null
  }
  allTags: {
    loadingState: LOADING_STATE
    error?: TServiceError | null
    list: TTagRs[]
  }
}

export type TTagCreationRs = components['schemas']['TagCreationRs']
export type TTagCreationRq = components['schemas']['TagCreationRq']
export type TTagUpdateRq = components['schemas']['TagUpdateRq']
