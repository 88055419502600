import { customPeriodOption } from 'components/filters/DateRangeFilter/constants'
import { BILLING_STATUS } from 'store/orderBillingInformation/types'
import { ORDERS_TABLE_TAB } from 'store/ordersList/types'
import { TAG_TYPE } from 'store/tags/types'
import { addDay } from 'utils/helpers/date/addDay'
import { subDay } from 'utils/helpers/date/subDay'

import { ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS, IState } from './types'

export const DEFAULT_BILLING_ORDERS_TABLE_BILLING_STATUS_FILTER = [
  {
    displayName: 'Pending billing information',
    name: BILLING_STATUS.PENDING_BILLING_INFO,
    priority: 1,
  },
  {
    displayName: 'Billing information complete',
    name: BILLING_STATUS.BILLING_INFO_COMPLETE,
    priority: 2,
  },
]

export const initialState: IState = {
  [ORDERS_TABLE_TAB.ALL]: {
    filtersData: {
      dateOfService: {
        period: customPeriodOption,
        from: subDay(new Date(), 30).toISOString(),
        to: addDay(new Date(), 30).toISOString(),
      },
      siteNames: [],
      urgencies: [],
      bodyParts: [],
      modalities: [],
      procedures: [],
      cptCodes: [],
      physicians: [],
      radiologists: [],
      contributingReaders: [],
      followUpDate: {
        from: null,
        period: null,
        to: null,
      },
      billingTypes: [],
      billingStatuses: [],
      tags: {
        [TAG_TYPE.ADMIN]: [],
        [TAG_TYPE.CUSTOMER]: [],
      },
      systemTags: [],
      proscanMrnSearch: '',
      proscanAccessionSearch: '',
      sourceMrnSearch: '',
      sourceAccessionSearch: '',
      siteSearch: '',
      patientSearch: '',
      isInactiveStatuses: false,
      orderStatuses: [],
    },
    columnsConfiguration: {
      [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.PRO_SCAN_MRN]: false,
      [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.CREATE_DATE]: false,
      [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_ACCESSION]: false,
      [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SOURCE_MRN]: false,
      [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.COMPANY]: false,
      [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_TYPE]: false,
      [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.BILLING_STATUS]: false,
      [ALL_ORDERS_TABLE_CONFIGURABLE_COLUMNS.SITE_NAME]: false,
    },
    queryData: {
      sort: undefined,
      page: 0,
      size: 10,
    },
  },
  [ORDERS_TABLE_TAB.PREPARATION]: {
    filtersData: {
      dateOfService: {
        period: null,
        from: null,
        to: null,
      },
      siteNames: [],
      holdReasons: [],
      urgencies: [],
      bodyParts: [],
      modalities: [],
      procedures: [],
      tags: {
        [TAG_TYPE.ADMIN]: [],
        [TAG_TYPE.CUSTOMER]: [],
      },
      systemTags: [],
      search: '',
    },
    queryData: {
      sort: '',
      page: 0,
      size: 10,
    },
  },
  [ORDERS_TABLE_TAB.ASSIGNMENT]: {
    filtersData: {
      urgencies: [],
      physicians: [],
      hasAssignedRadiologist: false,
      radiologists: [],
      dateOfService: {
        period: null,
        from: null,
        to: null,
      },
      bodyParts: [],
      modalities: [],
      procedures: [],
      siteNames: [],
      tags: {
        [TAG_TYPE.ADMIN]: [],
        [TAG_TYPE.CUSTOMER]: [],
      },
      systemTags: [],
      states: [],
      followUpDate: {
        period: null,
        from: null,
        to: null,
      },
    },
    queryData: {
      page: 0,
      size: 10,
    },
  },
  [ORDERS_TABLE_TAB.REPORT_QA]: {
    filtersData: {
      dateOfService: {
        period: null,
        from: null,
        to: null,
      },
      siteNames: [],
      radiologists: [],
      contributingReaders: [],
      reportQAReasons: [],
      tags: {
        [TAG_TYPE.ADMIN]: [],
        [TAG_TYPE.CUSTOMER]: [],
      },
      systemTags: [],
      urgencies: [],
      bodyParts: [],
      modalities: [],
      procedures: [],
      search: '',
    },
    queryData: {
      sort: '',
      page: 0,
      size: 10,
    },
  },
  [ORDERS_TABLE_TAB.BILLING]: {
    filtersData: {
      radiologists: [],
      dateOfService: {
        period: null,
        from: null,
        to: null,
      },
      patientDayOfBirth: null,
      bodyParts: [],
      modalities: [],
      procedures: [],
      cptCodes: [],
      companies: [],
      siteNames: [],
      billingTypes: [],
      billingStatuses: DEFAULT_BILLING_ORDERS_TABLE_BILLING_STATUS_FILTER,
      insuranceGroupNames: [],
      physicians: [],
      tags: {
        [TAG_TYPE.ADMIN]: [],
        [TAG_TYPE.CUSTOMER]: [],
      },
      systemTags: [],
      search: '',
    },
    queryData: {
      sort: '',
      page: 0,
      size: 10,
    },
  },
}

export const ORDERS_FILTERS_SLICE_NAME = 'ordersFilters'
