import {
  FLAG_SEVERITY_TYPE,
  FLAG_STATUS_TYPE,
} from 'components/flags/constants'
import { TBffCombinedOrderFlagRs } from 'store/assignProvidersTable/types'

export const isRequiredFlagUncompleted = (flag: TBffCombinedOrderFlagRs) =>
  !flag.completedInfo &&
  flag.status == FLAG_STATUS_TYPE.TODO &&
  flag.severity === FLAG_SEVERITY_TYPE.REQUIRED
